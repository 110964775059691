import React from 'react';
import commafy from '@hoffman/shared/src/utils/commafy';
import NavbarBlock from '@hoffman/amaris/src/blocks/NavbarBlock';
import SEO from '../../../../packages/docs/src/components/seo';
import BaseLayout from '@hoffman/shared/src/components/BaseLayout';
import ResidenceDetailPageSection from '@hoffman/shared/src/components/page-sections/ResidenceDetailPageSection';

const ResidenceDetail = (props) => {
  const data = props.pageContext.data;
  return (
    <BaseLayout>
      <SEO
        title={'# Bedrooms # Bathrooms Floor Plan | Amaris'}
        description={
          'This stunning [# bedrooms, # bathrooms, or suite type] condo could be your next home. Contact our leasing experts for a private tour.'
        }
      />
      <NavbarBlock />
      <ResidenceDetailPageSection
        room={data.unitName}
        bed={data.unitBeds.bedroomName}
        bath={data.unitBaths.bathroomName}
        hasBalcony={!!data.unitBalconySqFt}
        balcony={commafy(data.unitBalconySqFt) + ' Sq. Ft.'}
        floor={data.unitFloor.floorName}
        sqFt={commafy(data.unitInteriorSqFt) + ' Sq. Ft.'}
        downloadText="Download"
        downloadLink={data.unitPDF.asset.url}
        shareText="Share"
        shareLink={`mailto:?body=https://amaris.netlify.app/residences/${data.unitName}`}
        contactText="Contact"
        contactLink="/contact"
        image={data.unitImage.asset.fluid}
        placementImage={data.unitPlacementImage?.asset.fluid || null}
      />
    </BaseLayout>
  );
};

export default ResidenceDetail;
