import React, {Fragment} from 'react';
import styled from 'styled-components';
import {
  ArrowCta,
  BoxlessArrowCta,
} from '@hoffman/shared/src/components/Cta/cta';
import Img from 'gatsby-image';
import Hide from '@hoffman/shared/src/components/Helpers/Hide';

const ResidenceDetailPageSection = ({
  room,
  bed,
  bath,
  hasBalcony,
  balcony,
  floor,
  sqFt,
  downloadText,
  downloadLink,
  shareText,
  shareLink,
  contactText,
  contactLink,
  image,
  placementImage,
}) => {
  return (
    <Container>
      <ContentContainer>
        <BackButton>
          <BoxlessArrowCta
            color="#414448"
            link={'/residences'}
            tar={false}
            text="B A C K"
          />
        </BackButton>
        <RoomNumber>{room}</RoomNumber>
        <Line />
        {/* details for desktop view */}
        <InformationContainer>
          <Hide below breakpoint={1}>
            <DetailInformation>
              {bed} / {bath}
              {hasBalcony ? (
                <Fragment>
                  <br />
                  Balcony
                </Fragment>
              ) : null}
            </DetailInformation>
            <DetailInformation>
              Approx. <br />
              {sqFt}
            </DetailInformation>
            <DetailInformation>
              Balcony
              <br />
              {balcony}
            </DetailInformation>
          </Hide>
          {/* details for mobile views */}
          <Hide above breakpoint={1}>
            <DetailInformation>
              {bed} / {bath}
              {hasBalcony ? ' / Balcony' : null}
            </DetailInformation>
            <DetailInformation>Approx. {sqFt}</DetailInformation>
            <DetailInformation>Balcony: {balcony}</DetailInformation>
          </Hide>
        </InformationContainer>
        <Line />
      </ContentContainer>
      <ImageContainer>
        <div>
          <Img fluid={image} />
        </div>
      </ImageContainer>
      <CtaContainer>
        <ArrowContainer id={'view-unit' + room}>
          <ArrowCta
            tar={true}
            text={downloadText}
            color="grey"
            link={downloadLink}
          />
          <ArrowCta text={shareText} color="grey" link={shareLink} tar />
          <ArrowCta text={contactText} color="grey" link={contactLink} />
        </ArrowContainer>
      </CtaContainer>
      <PlacementImageContainer>
        <div>{placementImage && <Img fluid={placementImage} />}</div>
      </PlacementImageContainer>
    </Container>
  );
};

export default ResidenceDetailPageSection;

const Container = styled.div`
  color: ${({theme}) => theme.baseColors.DARK_GRAY};
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 20% 1fr auto;
  grid-template-areas:
    'details floorplan cta'
    'keyplan floorplan cta';
  margin: 128px 5% 5%;

  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    margin: 128px 5% 5%;
    grid-template-columns: 213px 1fr;
    grid-template-areas:
      'details details'
      'floorplan floorplan'
      'keyplan cta';
  }

  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    margin: 64px 5% 5%;
    grid-template-columns: 100%;
    grid-template-areas:
      'details'
      'floorplan'
      'keyplan'
      'cta';
  }
`;

const BackButton = styled.div`
  align-self: flex-start;
`;

const ContentContainer = styled.div`
  grid-area: details;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
  }
`;

const DescriptionContainer = styled.div``;

const RoomNumber = styled.div`
  font-size: 75px;
  font-family: ${({theme}) => theme.fontFaces.heading};
  margin: 50px 0;
  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    font-size: 60px;
  }
`;

const InformationContainer = styled.div`
  width: 84%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    align-items: center;
  }
`;

const DetailInformation = styled.div`
  font-family: ${({theme}) => theme.fontFaces.heading};
  font-size: ${({theme}) => theme.fontSizes[1]}px;
  font-weight: ${({theme}) => theme.fontWeights.light};
  text-transform: uppercase;
  line-height: 1.25em;
  margin: 0.4em 0;
  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    text-align: center;
  }
`;

const Line = styled.div`
  display: inline-block;
  border-bottom: 1px solid ${({theme}) => theme.baseColors.LIGHT_GRAY};
  margin: 10px 0;
  width: 84%;
  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    width: 33%;
  }
  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    display: inherit;
    display: inline-block;
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  grid-area: floorplan;
  .gatsby-image-wrapper {
    max-height: calc(100vh - 160px);
  }
  img {
    object-fit: contain !important;
  }
  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    width: 80%;
    margin: 16px 10%;
  }
  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    width: 100%;
    margin: 16px 0;
  }
`;

const PlacementImageContainer = styled.div`
  // todo: refactor to use fixed images, and get rid of magic value
  grid-area: keyplan;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  & > div {
    width: 213px;
    max-width: 22vw;
  }
  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    align-items: flex-start;
  }
  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    align-items: center;
  }
`;

const CtaContainer = styled.div`
  grid-area: cta;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  a {
    margin-top: 7px;
  }
  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
  }
  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    margin-top: 16px;
  }
`;

const ArrowContainer = styled.div`
  div {
    display: flex;

    > a {
      width: 100%;
      position: relative;
      text-align: center;
      text-transform: capitalize;
      letter-spacing: inherit;
      padding: 10px 40px;
      span {
        padding-right: 15px;
      }
      > a {
        position: absolute;
        top: 0;
        right: 10px;
      }
    }
  }

  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    display: block;
    width: 100%;
  }
`;
