import styled from 'styled-components';

interface propsHide {
  above?: boolean;
  below?: boolean;
  breakpoint: number;
}

const overBkCss = (props: propsHide) => {
  if (props.above) {
    return {display: 'none'};
  }
};

const underBkCss = (props: propsHide) => {
  if (props.above) {
    return {display: 'initial'};
  }
  if (props.below) {
    return {display: 'none'};
  }
};

export default styled.div<propsHide>`
  ${overBkCss}
  @media (max-width: ${({theme, breakpoint}) =>
    theme.breakpoints[breakpoint]}) {
    ${underBkCss}
  }
`;
